import PropTypes from 'prop-types';
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import AppConstants from '../app/constants';
import NavBar from './layout/NavBar/NavBar';
import SideMenu from './layout/SideMenu/SideMenu';

import '../styles/index.scss';

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
}

const Layout = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const data = useStaticQuery(graphql`
    query globalQuery {
      allDepartments {
        nodes {
          code
          codeRegion
          nom
          region {
            code
            nom
          }
        }
        group(field: region___code) {
          nodes {
            region {
              nom
              code
            }
          }
        }
      }
      allDataCsv(filter: {sexe: {eq: "0"}}) {
        nodes {
          dc
          dep
          hosp
          jour
          rad
          rea
          sexe
        }
      }

      franceStats: countriesStats(countryInfo: {iso2: {eq: "FR"}}) {
        active
        cases
        casesPerOneMillion
        country
        critical
        deaths
        deathsPerOneMillion
        recovered
        todayCases
        todayDeaths
      }
    }
  `);

  const [franceStats, setFranceStats] = React.useState(null);

  const regions = {};
  const departments = {};
  const mapRegions = [];
  const mapDepartments = [];
  const allDates = [];
  const dataByDate = {};
  const dataByDepartment = {};
  let lastDate = null;
  
  if (!franceStats) { 
    // Get all regions list
    data.allDepartments.group.map((item) => item.nodes[0].region).forEach((item) => {
      regions[item.code] = item;
    });

    // Get all depatments list
    data.allDepartments.nodes.forEach((item) => {
      departments[item.code] = item;
    });

    // Get regions ranges
    const regionsGlobalStats = {
      average: data.franceStats.cases / Object.keys(regions).length,
    };
    regionsGlobalStats.q1 = regionsGlobalStats.average / 2;
    regionsGlobalStats.q3 = regionsGlobalStats.average + (regionsGlobalStats.average / 2);

    // Get departments ranges<
    const departmentsGlobalStats = {
      average: data.franceStats.cases / Object.keys(departments).length,
    };
    departmentsGlobalStats.q1 = departmentsGlobalStats.average / 2;
    departmentsGlobalStats.q3 = departmentsGlobalStats.average + (departmentsGlobalStats.average / 2);

    // Extract dates and departments data by date from CSV
    data.allDataCsv.nodes.forEach((item) => {
      if (allDates.indexOf(item.jour) === -1) {
        allDates.push(item.jour);
      }

      dataByDate[item.jour] = dataByDate[item.jour] || {};
      dataByDate[item.jour].dc = dataByDate[item.jour].dc || 0;
      dataByDate[item.jour].dc += parseInt(item.dc, 10);
      dataByDate[item.jour].hosp = dataByDate[item.jour].hosp || 0;
      dataByDate[item.jour].hosp += parseInt(item.hosp, 10);
      dataByDate[item.jour].rad = dataByDate[item.jour].rad || 0;
      dataByDate[item.jour].rad += parseInt(item.rad, 10);
      dataByDate[item.jour].rea = dataByDate[item.jour].rea || 0;
      dataByDate[item.jour].rea += parseInt(item.rea, 10);
      dataByDate[item.jour].total = dataByDate[item.jour].dc + dataByDate[item.jour].hosp + dataByDate[item.jour].rea + dataByDate[item.jour].rad;

      dataByDepartment[item.dep] = dataByDepartment[item.dep] || {};
      dataByDepartment[item.dep][item.jour] = dataByDepartment[item.dep][item.jour] || {};
      dataByDepartment[item.dep][item.jour].dc = dataByDate[item.jour].dc || 0;
      dataByDepartment[item.dep][item.jour].dc += parseInt(item.dc, 10);
      dataByDepartment[item.dep][item.jour].hosp = dataByDate[item.jour].hosp || 0;
      dataByDepartment[item.dep][item.jour].hosp += parseInt(item.hosp, 10);
      dataByDepartment[item.dep][item.jour].rad = dataByDate[item.jour].rad || 0;
      dataByDepartment[item.dep][item.jour].rad += parseInt(item.rad, 10);
      dataByDepartment[item.dep][item.jour].rea = dataByDate[item.jour].rea || 0;
      dataByDepartment[item.dep][item.jour].rea += parseInt(item.rea, 10);
      dataByDepartment[item.dep][item.jour].total = dataByDepartment[item.dep][item.jour].dc + dataByDepartment[item.dep][item.jour].hosp + dataByDepartment[item.dep][item.jour].rea + dataByDepartment[item.dep][item.jour].rad;
    });

    lastDate = allDates[allDates.length - 1];

    const dailyData = {};

    Object.entries(dataByDate).forEach(([day, item], index) => {
      if (index > 0) {
        dailyData[day] = {
          dc: item.dc - dataByDate[Object.keys(dataByDate)[index - 1]].dc,
          hosp: item.hosp - dataByDate[Object.keys(dataByDate)[index - 1]].hosp,
          rea: item.rea - dataByDate[Object.keys(dataByDate)[index - 1]].rea,
          rad: item.rad - dataByDate[Object.keys(dataByDate)[index - 1]].rad,
          total: item.total - dataByDate[Object.keys(dataByDate)[index - 1]].total,
        };
      }
    });

    // Get total stats per department
    data.allDataCsv.nodes.filter((item) => item.jour === lastDate).forEach((item) => {
      if (item.dep) {
        departments[item.dep] = departments[item.dep] || {};

        departments[item.dep].totalDc = parseInt(item.dc, 10);
        departments[item.dep].totalHosp = parseInt(item.hosp, 10);
        departments[item.dep].totalRad = parseInt(item.rad, 10);
        departments[item.dep].totalRea = parseInt(item.rea, 10);

        const departmentTotalCases = departments[item.dep].totalDc + departments[item.dep].totalHosp + departments[item.dep].totalRea + departments[item.dep].totalRad;
        departments[item.dep].total = departmentTotalCases;

        let color = AppConstants.MAP_COLORS['0'];
        if (departmentTotalCases > 0 && departmentTotalCases <= departmentsGlobalStats.q1) { color = AppConstants.MAP_COLORS['1']; }
        if (departmentTotalCases > departmentsGlobalStats.q1 && departmentTotalCases <= departmentsGlobalStats.average) { color = AppConstants.MAP_COLORS['2']; }
        if (departmentTotalCases > departmentsGlobalStats.average && departmentTotalCases <= departmentsGlobalStats.q3) { color = AppConstants.MAP_COLORS['3']; }
        if (departmentTotalCases > departmentsGlobalStats.q3) { color = AppConstants.MAP_COLORS['4']; }

        mapDepartments.push({ code: item.dep, color });

        // Make "Paris première couronne" look as Paris 75
        if (item.dep === '75') {
          mapDepartments.push({ code: '75-92-93-94', color});
        }
      }
    });

    // Get total stats per region
    Object.values(departments).forEach((item) => {
      if (item.codeRegion) {

        regions[item.codeRegion].totalDc = (regions[item.codeRegion].totalDc || 0) + item.totalDc;
        regions[item.codeRegion].totalHosp = (regions[item.codeRegion].totalHosp || 0) + item.totalHosp;
        regions[item.codeRegion].totalRad = (regions[item.codeRegion].totalRad || 0) + item.totalRad;
        regions[item.codeRegion].totalRea = (regions[item.codeRegion].totalRea || 0) + item.totalRea;
        regions[item.codeRegion].departments = regions[item.codeRegion].departments || [];
        regions[item.codeRegion].departments.push(item);

        const regionTotalCases = regions[item.codeRegion].totalDc + regions[item.codeRegion].totalHosp + regions[item.codeRegion].totalRea + regions[item.codeRegion].totalRad;
        regions[item.codeRegion].total = regionTotalCases;
      }
    });

    // Map regions colors
    Object.values(regions).forEach((item) => {
      if (item.code) {
        let color = AppConstants.MAP_COLORS['0'];

        if (item.total > 0 && item.total <= regionsGlobalStats.q1) { color = AppConstants.MAP_COLORS['1']; }
        if (item.total > regionsGlobalStats.q1 && item.total <= regionsGlobalStats.average) { color = AppConstants.MAP_COLORS['2']; }
        if (item.total > regionsGlobalStats.average && item.total <= regionsGlobalStats.q3) { color = AppConstants.MAP_COLORS['3']; }
        if (item.total > regionsGlobalStats.q3) { color = AppConstants.MAP_COLORS['4']; }

        mapRegions.push({ code: item.code, color });
      }
    });

    setFranceStats({
      allDates,
      lastDate,
      dataByDate,
      regions,
      departments,
      mapDepartments,
      mapRegions,
      daily: dailyData,
    });

    return null;
  }

  return (
    <>
      <NavBar toggleMenu={() => setIsMenuOpen(!isMenuOpen)} />

      <SideMenu isMenuOpen={isMenuOpen} toggleMenu={() => setIsMenuOpen(!isMenuOpen)} />
      
      <div className="content">
        <div className="content-body">
          <div className="container">
            {React.cloneElement(children, { franceStats })}

            <footer>
              Par &nbsp; <a href="https://www.kelyo.fr/" target="_blank" rel="noopener noreferrer">Khalil EL ISMAILI</a> <br />
              Sources: &nbsp;
              <a href="https://www.worldometers.info/coronavirus/" target="_blank" rel="noopener noreferrer">Worldometers</a>, &nbsp;
              <a href="https://systems.jhu.edu/" target="_blank" rel="noopener noreferrer">JHU CSSE</a>, &nbsp;
              <a href="https://www.santepubliquefrance.fr/">Santé Publique France</a>
            </footer>
          </div>
        </div>

        <footer>
          Par &nbsp; <a href="https://www.kelyo.fr/" target="_blank" rel="noopener noreferrer">Khalil EL ISMAILI</a> &nbsp; - &nbsp;
          Sources: &nbsp;
          <a href="https://www.worldometers.info/coronavirus/" target="_blank" rel="noopener noreferrer">Worldometers</a>, &nbsp;
          <a href="https://systems.jhu.edu/" target="_blank" rel="noopener noreferrer">JHU CSSE</a>, &nbsp;
          <a href="https://www.santepubliquefrance.fr/" target="_blank" rel="noopener noreferrer">Santé Publique France</a>
        </footer>
      </div>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
};

Layout.defaultProps = {
  children: null,
};

export default Layout
