import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import AppConstants from '../../../app/constants';
import Logo from '../../../assets/images/logo.svg';

const NavBar = ({ toggleMenu }) => (
  <>

    <div className="header">
      <div className="nav-header">
        <button className="nav-control" onClick={toggleMenu}>
          <div className="burger">
            <FontAwesomeIcon icon={faBars} />
          </div>
        </button>

        <Link to={AppConstants.ROUTE_PATHS.homepage} className="brand-logo">
          <img src={Logo} className="logo-image" alt="Pandemy.info" />
        </Link>
      </div>

      <div className="header-content">
        <div className="navbar navbar-expand">
          <div className="navbar-collapse">
            <div className="header-left">
              {/* <div className="search-bar">
                <input type="search" className="form-control" placeholder="Rechercher..." />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

NavBar.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
};

export default NavBar;
