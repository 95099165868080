import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '@reach/router';

const SideMenuItem = ({
  icon, label, to, onClick,
}) => {

  let isExternalLink = false;

  if (to.startsWith('http')) {
    isExternalLink = true;
  }

  if (isExternalLink) {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer" className="side-menu-item">
        {React.cloneElement(icon, { className: 'icon' })}

        <span className="label">{label}</span>
      </a>
    );
  }

  return (
    <li>
      <Link
        to={to}
        getProps={({ isCurrent }) => ({
          className: `side-menu-item ${isCurrent ? 'active' : ''}`,
        })}
        onClick={onClick}
      >
        <FontAwesomeIcon icon={icon} size="lg" />
        <span className="item-text">{label}</span>
      </Link>
    </li>
  );
};

SideMenuItem.propTypes = {
  icon: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  to: PropTypes.string.isRequired,
};

SideMenuItem.defaultProps = {
  onClick: () => {},
};

export default SideMenuItem;
