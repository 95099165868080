import PropTypes from 'prop-types';
import React from 'react';
import { faGlobeEurope, faMap, faMapMarked, faMapPin } from '@fortawesome/free-solid-svg-icons';

import AppConstants from '../../../app/constants';
import SideMenuItem from './SideMenuItem';

import './SideMenu.scss';

const SideMenu = ({ isMenuOpen, toggleMenu }) => {

  return (
    <div className={`quicknav ${ isMenuOpen ? 'open' : ''}`}>
      <ul className="menu">
        <SideMenuItem
          to={AppConstants.ROUTE_PATHS.homepage}
          icon={faMap}
          label="France"
          onClick={toggleMenu}
        />

        <SideMenuItem
          to={AppConstants.ROUTE_PATHS.regions}
          icon={faMapMarked}
          label="Régions"
          onClick={toggleMenu}
        />

        <SideMenuItem
          to={AppConstants.ROUTE_PATHS.departments}
          icon={faMapPin}
          label="Départements"
          onClick={toggleMenu}
        />

        <SideMenuItem
          to={AppConstants.ROUTE_PATHS.countries}
          icon={faGlobeEurope}
          label="Tous les pays"
          onClick={toggleMenu}
        />
      </ul>
    </div>
  );
}

SideMenu.propTypes = {
  isMenuOpen: PropTypes.bool,
  toggleMenu: PropTypes.func,
};

SideMenu.defaultProps = {
  isMenuOpen: false,
  toggleMenu: () => {},
};

export default SideMenu;
