export default {
  MAP_COLORS: {
    0: '#ebeff2',
    1: '#b8b5f2',
    2: '#8b87c7',
    3: '#756fbf',
    4: '#55508f',
  },

  ROUTE_PATHS: {
    homepage: '/',
    departments: '/departments',
    regions: '/regions',
    countries: '/countries',
  },

  REGION_DEPARTMENTS: {
    '84': ['15', '63', '03', '42', '43', '69', '07', '26', '01', '38', '74', '73'],
    '27': ['89', '58', '71', '21', '39', '25', '70', '90'],
    '53': ['29', '22', '56', '35'],
    '24': ['28', '41', '37', '36', '18', '45'],
    '94': ['2B', '2A'],
    '44': ['08', '10', '51', '52', '54', '55', '57', '67', '68', '88'],
    '32': ['62', '59', '02', '60', '80'],
    '11': ['78', '85', '77', '91', '92', '75', '94', '93'],
    '28': ['50', '14', '61', '27', '76'],
    '75': ['79', '86', '87', '23', '19', '87', '16', '17', '33', '24', '47', '40', '64'],
    '76': ['46', '12', '48', '30', '34', '81', '82', '32', '31', '11', '65', '09', '66'],
    '52': ['44', '85', '49', '53', '72'],
    '93': ['84', '13', '83', '04', '05', '06'],
    '01': ['971'],
    '03': ['973'],
    '02': ['972'],
    '06': ['976'],
    '04': ['974'],
  },

  SEX: {
    all: '0',
    male: '1',
    female: '2',
  },

  SEX_COLORS: {
    male: '#b3dcfc',
    female: '#f8c6c6',
  },
};