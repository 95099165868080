// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-department-page-template-js": () => import("./../src/templates/DepartmentPageTemplate.js" /* webpackChunkName: "component---src-templates-department-page-template-js" */),
  "component---src-templates-region-page-template-js": () => import("./../src/templates/RegionPageTemplate.js" /* webpackChunkName: "component---src-templates-region-page-template-js" */),
  "component---src-templates-country-page-template-js": () => import("./../src/templates/CountryPageTemplate.js" /* webpackChunkName: "component---src-templates-country-page-template-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-countries-js": () => import("./../src/pages/countries.js" /* webpackChunkName: "component---src-pages-countries-js" */),
  "component---src-pages-departments-js": () => import("./../src/pages/departments.js" /* webpackChunkName: "component---src-pages-departments-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-regions-js": () => import("./../src/pages/regions.js" /* webpackChunkName: "component---src-pages-regions-js" */)
}

